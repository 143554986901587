<template>
  <div>
    <Navigation
      v-bind:Breadcrumb="[
        { name: 'Home', route: 'Home' },
        { name: 'Staff', route: 'Admin' },
        { name: 'Shop Products', route: 'Admin_ShopProducts' },
        { name: 'Update Product', route: 'Admin_ShopProducts_Update' },
      ]"
    />
    <div class="placeholder">
      <div class="row">
        <div class="col-6">
          <div class="error" v-for="error in errors" :key="error">
            {{ error }}
          </div>
          <div class="success" v-for="message in messages" :key="message">
            {{ message }}
          </div>
          <form class="form" v-on:submit.prevent="UpdateProduct">
            <label for="form.title">Product Title</label>
            <input type="text" class="input" v-model="form.title" required />
            <label for="form.description">Description</label>
            <textarea
              class="input left"
              v-model="form.description"
              rows="4"
              required
            ></textarea>
            <label for="form.nett">Nett Price (EXCLUDING TAX)</label>
            <input
              type="number"
              min="1"
              step="any"
              class="input"
              v-model="form.nett"
              required
            />
            <label for="form.gross">Gross Price (INCLUDING TAX)</label>
            <input
              type="number"
              min="1"
              step="any"
              class="input"
              v-model="form.gross"
              required
            />
            <label for="form.min_quantity">Minimum Quantity Per Sale</label>
            <input
              type="number"
              min="1"
              class="input"
              v-model="form.min_quantity"
              required
            />
            <label for="form.max_quantity">Maximum Quantity Per Sale</label>
            <input
              type="number"
              min="1"
              class="input"
              v-model="form.max_quantity"
              required
            />
            <label for="form.status">Status</label>
            <select
              name="form.status"
              id="form.status"
              class="input"
              v-model="form.status"
            >
              <option value="AVAILABLE">Available</option>
              <option value="SOLDOUT">Sold Out</option>
              <option value="COMINGSOON">Coming Soon</option>
              <option value="DISCONTINUED">Discontinued</option>
            </select>
            <div class="row">
              <div class="col-4">
                <img :src="form.image" />
              </div>
              <div class="col-8">
                <label for="form.image">Image</label>
                <input
                  type="file"
                  class="input"
                  accept="image/*"
                  @change="imageUpload"
                />
              </div>
            </div>
            <br />
            <br />
            <input type="submit" class="btn btn-green" value="Save Changes" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

import Navigation from "@/components/Navigation.vue";

export default {
  setup() {
    const store = useStore();

    return { store };
  },
  data() {
    return {
      messages: "",
      errors: "",
      form: {
        title: "",
        description: "",
        nett: 0,
        gross: 0,
        min_quantity: 1,
        max_quantity: 1,
        status: "AVAILABLE",
        image: null,
      },
    };
  },
  components: {
    Navigation,
  },
  methods: {
    UpdateProduct() {
      this.store
        .dispatch("Admin/UpdateShopProduct", this.form)
        .then((result) => {
          this.messages = result.messages;
          this.errors = "";
        })
        .catch((err) => {
          this.errors = err.messages;
          this.messages = "";
          console.log(err);
        });
    },
    imageUpload(e) {
      this.form.image = e.target.files[0];
      console.log(this.form.image);
    },
    GetProduct() {
      this.store
        .dispatch("Admin/GetShopProduct", this.$route.params.id)
        .then((data) => {
          this.form = data.data;
          this.errors = "";
        })
        .catch((err) => {
          this.errors = err.messages;
        });
    },
  },
  created() {
    this.GetProduct();
  },
};
</script>

<style></style>
